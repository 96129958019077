// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

function unwrap_response_recordEventV5(u) {
  var v = u.__typename;
  if (v === "EventRecorded") {
    return {
            NAME: "EventRecorded",
            VAL: u
          };
  } else {
    return {
            NAME: "UnselectedUnionMember",
            VAL: v
          };
  }
}

function wrap_response_recordEventV5(v) {
  if (v.NAME === "UnselectedUnionMember") {
    return {
            __typename: v.VAL
          };
  } else {
    return v.VAL;
  }
}

var variablesConverter = {"recordEventActorInput":{},"recordEventV5Input":{"event":{"r":"recordEventV5EventInput"},"actor":{"r":"recordEventActorInput"}},"recordEventV5EventInput":{"occurredAt":{"b":""},"data":{"b":""}},"__root":{"input":{"r":"recordEventV5Input"}}};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var wrapResponseConverter = {"__root":{"recordEventV5":{"u":"response_recordEventV5"}}};

var wrapResponseConverterMap = {
  response_recordEventV5: wrap_response_recordEventV5
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"recordEventV5":{"u":"response_recordEventV5"}}};

var responseConverterMap = {
  response_recordEventV5: unwrap_response_recordEventV5
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function actorTypeV5_decode($$enum) {
  if ($$enum === "CLIENT" || $$enum === "USER" || $$enum === "QUALIFIED_AGENT") {
    return $$enum;
  }
  
}

var actorTypeV5_fromString = actorTypeV5_decode;

var Utils = {
  actorTypeV5_decode: actorTypeV5_decode,
  actorTypeV5_fromString: actorTypeV5_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventLogs_RecordEventV5Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "recordEventV5",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v3/*: any*/),
            "type": "EventRecorded",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventLogs_RecordEventV5Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "recordEventV5",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1e039d215bc0a849ae0f00577c1c0b83",
    "id": null,
    "metadata": {},
    "name": "EventLogs_RecordEventV5Mutation",
    "operationKind": "mutation",
    "text": "mutation EventLogs_RecordEventV5Mutation(\n  $input: RecordEventV5Input!\n) {\n  recordEventV5(input: $input) {\n    __typename\n    ... on EventRecorded {\n      __typename\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.unwrap_response_recordEventV5 = unwrap_response_recordEventV5;
exports.wrap_response_recordEventV5 = wrap_response_recordEventV5;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
